interface Variant {
  [key: string]: string;
}

export const variants = ["map", "wistia", "vimeo"] as const;

export const variantClasses = {
  map: "w-full h-[280px]",
  wistia: "wistia w-full h-[30%] lg:h-[90%]",
  vimeo: "w-full h-full",
} as Variant;
